<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.customerByDay')">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-8 col-md-8 col-lg-8 col-xl-4 my-1">
            {{ $t('message.machineId') }} :
            <br />
            <model-list-select :list="machineData" v-model="searchData.machineId" option-value="machineId" option-text="name" placeholder="Select Machine ID"></model-list-select>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.selectDateTime") }} :
            <br />
            <date-time-picker type="date" v-model="searchData.datetime"></date-time-picker>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-2 my-3">
            <b-btn class="col-sm-12 col-md-12 ml-12 mr-3 my-1" variant="primary" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
          </div>
        </div>
        <br />
      </b-form>

      <div class="row" v-if="series[0].data != ''">
        <div id="chart" class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div style="font-size: 16px; font-weight: bold">
            {{$t('message.customerByDay')}}
            <span style="color:green;" v-if="diffPercent >= 0 ">
              <span style="font-size: 1.5em;">&uarr;</span>
              {{ diffPercent }}%
            </span>
            <span style="color: red;" v-if="diffPercent < 0 ">
              <span style="font-size: 1.5em;">&darr;</span>
              {{ diffPercent }}%
            </span>
          </div>
          <apexchart ref="chart" type="area" height="350" :options="options" :series="series"></apexchart>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import webServices from '../../script'
// import DateTimePicker from '../../components/DateTimePicker/MonthCustom'
import DateTimePicker from '../../components/DateTimePicker/Custom'

import { MultiSelect, MultiListSelect, ListSelect, ModelListSelect } from '../../custom_modules/search-select'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'

export default {
  name: 'orders',
  components: {
    DateTimePicker,
    MultiSelect,
    MultiListSelect,
    ListSelect,
    ModelListSelect,
    VueJsonPretty
  },
  data() {
    return {
      language: window.localStorage.getItem('language'),
      role_id: window.localStorage.getItem('roleID'),
      userBusiness: window.localStorage.getItem('business'),
      selectedProduct: [],
      machineData: [],
      searchData: {
        datetime: [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
      },
      diffPercent: 0,
      series: [
        {
          data: []
        }
      ],
      options: {
        colors: ['#00e396', '#3498DB', '#FEB019', '#9C27B0', '#D92139'],
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [],
          type: 'category',
          tickAmount: undefined,
          tickPlacement: 'between',
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: 'bottom',
          labels: {
            show: true,
            // rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '12px',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value).toLocaleString()
            }
          }
        }
      }
    }
  },

  computed: {},

  async mounted() {
    await this.setParams(this.$route.query)
    await this.getMachine()
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    setParams(query) {
      if (Object.keys(query).length != 0) {
        // console.log(query)
        this.fromdate = query.from
        this.todate = query.to
        this.searchData.datetime = [query.from, query.to]
        this.searchData.productId = query.productId || ''
        this.searchData.machineId = query.machineId || ''
      }
    },

    async getMachine() {
      this.$Progress.start()
      await this.axios
        .get(`/machines/list`)
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
        })
        .catch(err => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },

    async searchFn() {
      await this.getSaleByDate()
    },

    async getSaleByDate() {
      const params = {
        machineId: this.searchData.machineId,
        from: (this.searchData.from = moment(this.searchData.datetime[0]).format('YYYY-MM-DD')),
        to: (this.searchData.to = moment(this.searchData.datetime[1]).format('YYYY-MM-DD'))
      }

      console.log(params)

      await this.axios
        .get(`/analyze/customerbydate`, {
          params
        })
        .then(async res => {
          console.log(res.data)

          this.diffPercent = res.data.diffPercent

          const { series, xAxis } = await res.data.current.reduce(
            (prev, cur) => {
              prev.series.push(cur.amount), prev.xAxis.push(cur.date)
              return prev
            },
            { series: [], xAxis: [] }
          )

          // console.log('series : ', series)
          // console.log('xAxis : ', xAxis)

          this.series = [
            {
              name: 'amount',
              data: series
            }
          ]
          this.options.xaxis.categories = xAxis

          if (this.$refs.chart) this.$refs.chart.refresh()
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },

    changeTab(params) {
      this.rowData = {}
      this.totalRows = 0
      this.totalSubRows = 0
      this.summary = {}
      this.searchData.type = params
      if (!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) {
        this.searchFn()
      }
    }
  }
}
</script>

